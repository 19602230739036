import styled from "styled-components";

interface IButtonProps {
	intent: string;
	fontSize: number;
}

function buttonColorsForIntent(intent: string) {
	if (intent === "primary") {
		return {
			text: "white",
			fill: "black",
			hoverFill: "#2d2d2d",
			border: "black",
		};
	} else {
		return {
			text: "white",
			fill: "transparent",
			hoverFill: "#2d2d2d",
			border: "white",
		};
	}
}

const MAPrimaryButton = styled.button<IButtonProps>`
	font-size: ${(props) => props.fontSize}px;
	border: 1px solid ${(props) => buttonColorsForIntent(props.intent).border};
	padding: 4px 16px;
	border-radius: 4px;
	text-align: center;
	color: ${(props) => buttonColorsForIntent(props.intent).text};
	background-color: ${(props) => buttonColorsForIntent(props.intent).fill};
	transition: all 0.1s ease-in-out;
	cursor: pointer;
	&:disabled {
		opacity: 0.5;
		cursor: default;
	}
	&:not(:disabled) {
		&:hover {
			transform: scale(1.05);
			cursor: pointer;
			background-color: ${(props) =>
				buttonColorsForIntent(props.intent).hoverFill};
		}
		&:active {
			transform: scale(1.1);
			cursor: pointer;
		}
	}
`;

export { MAPrimaryButton };
