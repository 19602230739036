import styled from "styled-components";

export interface IContainerBoxProps {
	width?: number | string;
	height?: number | string;
	padding?: string;
	backgroundColor?: string;
	border?: string;
	direction?: string;
	alignItems?: string;
	justifyContent?: string;
	alignContent?: string;
	flex?: string;
}

export const ContainerBox = styled("div")<IContainerBoxProps>`
	width: ${(props) => props.width || "100%"};
	${(props) => (props.height ? "height:" + props.height + ";" : null)};
	padding: ${(props) => props.padding || "0"};
	background-color: ${(props) => props.backgroundColor || "transparent"};
	border: ${(props) => props.border || "0"};
	display: flex;
	flex: ${(props) => props.flex || "1"};
	flex-direction: ${(props) => props.direction || "column"};
	align-items: ${(props) => props.alignItems || "center"};
	justify-content: ${(props) => props.justifyContent || "center"};
	align-content: ${(props) => props.alignContent || "center"};
`;
