import * as React from "react";
import styled from "styled-components";

import { MADataModel } from "../_lib/MADataModel";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { MAContentContainer } from "./MAContentContainer";

type ImageViewerProps = {};

const Container = styled.div`
	background-color: white;
	border-color: #d0d0d0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	margin-bottom: 12px;
	position: relative;
`;

const Image = styled.img``;

const Button = styled.button`
	width: 32px;
	height: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 4px;
	border: 1px solid #4d4d4d;
	background-color: #1d1d1d;
	border-radius: 5px;
	box-shadow: 0 0 2px #c0c0c0;
	color: #e0e0e0;
	font-weight: bold;
	&:not(last) {
		margin-right: 5px;
	}
	&:focus {
		border: 1px solid orange;
		outline: none;
	}
	&:hover {
		opacity: 0.6;
	}
`;

const ButtonBar = styled.div`
	position: absolute;
	top: 4px;
	right: 4px;
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

export class MAImageViewer extends React.Component<ImageViewerProps, {}> {
	render() {
		let theURL: string = "";
		const mediaItem = MADataModel.sharedInstance().selectedMediaItem;
		if (mediaItem) {
			theURL = MADataModel.sharedInstance().urlForMediaWithID(mediaItem.id);
		} else {
			theURL = "";
		}

		return (
			<MAContentContainer bottomOffset={20}>
				<TransformWrapper initialScale={2}>
					{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
						<Container>
							<TransformComponent
								wrapperStyle={{ width: "100%", height: "100%" }}
							>
								<Image src={theURL} alt="test" />
							</TransformComponent>
							<ButtonBar>
								<Button onClick={() => zoomIn()}>+</Button>
								<Button onClick={() => zoomOut()}>-</Button>
								<Button onClick={() => resetTransform()}>1:1</Button>
							</ButtonBar>
						</Container>
					)}
				</TransformWrapper>
			</MAContentContainer>
		);
	}
}
