import * as React from 'react';
import styled from 'styled-components';

import { ContainerBox } from '../_lib/ContainerBox';
import {ExternalItem} from '../_lib/Item/ExternalItem';
import * as TextStyles from '../_lib/TextStyles';
import {MAImage} from '../_lib/MAImage';
import {MAPrimaryButton} from "../_lib/MAButton";
import {MANavigationItem} from "../_lib/MANavigationItem";
import {MADataModel} from "../_lib/MADataModel";

interface IMADigitalNZItemViewProps {
    item: ExternalItem;
}

interface IContainerProps {
    externalLinkAvailable?: boolean;
}

const Container=styled.div<IContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    background-color: white;
    border: 1px solid #4d4d4d;
    border-radius: 4px;
    padding: 20px;
    overflow: auto;
`;


export class MADigitalNZItemView extends React.Component<IMADigitalNZItemViewProps,{}> {

    itemField(data:any,propName:string) {
        return (
            <DNZFieldContainer>
            <ContainerBox
                direction={'column'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
            >
                <TextStyles.TextParagraph
                    color={'#8d8d8d'}
                    fontSize={'14px'}
                >
                    {propName}
                </TextStyles.TextParagraph>
                <TextStyles.TextParagraph
                    color={'#1d1d1d'}
                    fontSize={'16px'}
                    alignment={'left'}
                >
                    {data[propName]}
                </TextStyles.TextParagraph>

            </ContainerBox>
            </DNZFieldContainer>
        );
    }


    dataToDisplay() {
        return {
            'Title': this.props.item.title,
            'Category':this.props.item.category,
            'Description':this.props.item.description,
            'Copyright': this.props.item.copyright,
            'Source URL': this.props.item.sourceURL,
            'License': this.props.item.license,
            'Publisher': this.props.item.publisher,
            'Syndication date': this.props.item.syndicationDate,
            'Display date': this.props.item.displayDate,
            'Author': this.props.item.author,
            'Date': this.props.item.date,
            'Content partner': this.props.item.contentPartner,
            'Thumbnail': this.props.item.thumbnailURL,
        };
    }


    renderImage() {   
        return (
            <div>
                <MAImage 
                    src={this.props.item.getThumbnailURL()} 
                    width={'100%'}
                    height={'100%'}
                />
            </div>
        );
    }

    showSource(url:string) {
        const sourceNavItem=new MANavigationItem(
            this.props.item.getTitle(),
            'Back',
            'webview',
            {
                url: url,
            }
        );
        MADataModel.sharedInstance().pushAppNavigationStack(sourceNavItem);
    }


    renderExternalLink() {
        const all:any=this.props.item.valueForExtraField('all');
        if (all.landing_url) {
            return (
                <NativeItemButtonContainer>
                    <MAPrimaryButton
                        intent={'primary'}
                        fontSize={18}
                        onClick={()=>this.showSource(all.landing_url)}
                    >
                        View original content
                    </MAPrimaryButton>
                </NativeItemButtonContainer>
            );
        }
        return undefined;
    }


    getExternalURL() {
        let allFields=this.props.item.valueForExtraField('all');
        if (allFields) {
            if (allFields.hasOwnProperty('landing_url')) {
                return allFields.landing_url;
            }
        }
        return '';
    }


    render() {
        let url=this.getExternalURL();
        let data=this.dataToDisplay();
        return (
                <ContainerBox direction={'column'}>
                    <Container>
                        {this.itemField(data,'Title')}
                        {this.itemField(data,'Category')}
                        {this.itemField(data,'Description')}
                        {this.itemField(data,'Copyright')}
                        {this.itemField(data,'Source URL')}
                        {this.itemField(data,'License')}
                        {this.itemField(data,'Publisher')}
                        {this.itemField(data,'Syndication date')}
                        {this.itemField(data,'Display date')}
                        {this.itemField(data,'Author')}
                        {/* {this.itemField(data,'Date')} */}
                        {this.itemField(data,'Content partner')}
                        {this.props.item.thumbnailURL!=='' ? this.renderImage() : null}
                        {url!=='' ? this.renderExternalLink() : null}
                    </Container>
            </ContainerBox>

        );
    }
}



const NativeItemButtonContainer=styled.div`
    
    display: flex;
    border: 0;
    overflow: auto;
    flex-direction: column;
    width: 100%;
    background-color: transparent;
    padding: 0;
    margin-top: 20px;
`;


const DNZFieldContainer=styled.div`
    padding-bottom: 20px;
`;


