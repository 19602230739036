import styled from 'styled-components';



const kFontBaseSize: string = "14px";

interface ITextStyleProps {
    fontSize?: string;
    color?: string;
    alignment?: string;
}

const BaseStyle=styled.div`
    font-family: sans-serif;
    line-height: 100%;
    display: block;
`;

export const MAPageTitle=styled(BaseStyle)<ITextStyleProps>`
    font-size: max(5.5vw,48px);
    font-family: serif;
    color: white;
    text-align: center;
    padding-bottom: 6px;
    text-shadow: 0 0 8px #c0c0c0;
`;

export const MAPageSubtitle=styled(BaseStyle)<ITextStyleProps>`
    font-size: max(1.5vw,22px);
    font-family: serif;
    color: white;
    text-align: center;
    padding-bottom: 6px;
    text-shadow: 0 0 8px #c0c0c0;
`;

export const TextParagraph=styled(BaseStyle)<ITextStyleProps>`
    font-size: ${props=>props.fontSize || 14};
    padding-bottom: 6px;
    line-height: 110%;
    text-align: left;
    color: ${props=>props.color || '#2d2d2d'};
    text-align: ${props=>props.alignment || 'left'};
`;


