import * as React from "react";
import { ContainerBox } from "../_lib/ContainerBox";
import ALSearchField from "../_lib/ALSearchField";
import { TextParagraph } from "../_lib/TextStyles";
import { MAFooter } from "../_lib/MAFooter";
import { MADataModel } from "../_lib/MADataModel";
import { observer } from "mobx-react";
import { MAPrimaryButton } from "../_lib/MAButton";
import { MANavigationItem } from "../_lib/MANavigationItem";
import styled from "styled-components";

const kHeaderHeight: number = 220;

interface IHomePageProps {
	history?: any;
}

interface IHomePageState {
	searchText: string;
	isLoading: boolean;
	searchWasCancelled: boolean;
}

function kaitiakiHeight(): number {
	return Math.floor(MADataModel.sharedInstance().windowHeight / 1.4);
}

function kaitiakiYPos(): number {
	return kHeaderHeight;
}

const KaitiakiContainer = styled.div`
	position: fixed;
	left: 0px;
	top: ${kaitiakiYPos()}px;
`;

const AboutContainer = styled.div`
	position: fixed;
	bottom: 50px;
	left: 0px;
	right: 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const ShadowedText = styled(TextParagraph)`
	text-shadow: 0 0 4px #404040;
	background-color: rgba(147, 108, 50, 0.6);
	padding: 8px;
	border-radius: 8px;
	border: 1px solid rgba(147, 108, 50, 1);
`;

@observer
export class MAHomePage extends React.Component<
	IHomePageProps,
	IHomePageState
> {
	constructor(props: IHomePageProps) {
		super(props);
		this.state = {
			searchText: "",
			isLoading: false,
			searchWasCancelled: false,
		};
	}

	showAbout() {
		const navItem = new MANavigationItem("About this site", "Home", "credits");
		MADataModel.sharedInstance().pushAppNavigationStack(navItem);
	}

	doSearch() {
		const navItem = new MANavigationItem("Search results", "", "results");
		MADataModel.sharedInstance().setSearchValue(this.state.searchText);
		MADataModel.sharedInstance().pushAppNavigationStack(navItem);
	}

	searchValueChanged(newText: string) {
		this.setState({
			searchText: newText,
		});
	}

	showAboutButton() {
		return (
			<AboutContainer>
				<MAPrimaryButton
					intent="secondary"
					fontSize={14}
					onClick={() => this.showAbout()}
					title={"View credits and information about this site"}
				>
					About
				</MAPrimaryButton>
			</AboutContainer>
		);
	}

	handleCommit(newText: string) {
		this.setState({ searchText: newText });
		this.doSearch();
	}

	public render() {
		MADataModel.sharedInstance().resetSearchResults();
		return (
			<ContainerBox direction="column" height={"100%"}>
				<KaitiakiContainer>
					<img
						src="./img/kaitiaki.png"
						style={{ height: kaitiakiHeight() }}
						alt={"kaitiaki"}
					/>
				</KaitiakiContainer>
				<ContainerBox
					direction={"row"}
					justifyContent={"space-between"}
					style={{ zIndex: 9999 }}
				>
					<ContainerBox flex={"2"} padding={"0 180px"}>
						<ShadowedText fontSize={"22px"} color={"white"}>
							Type a search term then hit Search.
						</ShadowedText>
						<br />
						<div>
							<ALSearchField
								searchValue={""}
								changeCallback={(newText: string) =>
									this.searchValueChanged(newText)
								}
								width={"200px"}
								commitCallback={(newText: string) => this.handleCommit(newText)}
							/>
							<br />
							<br />
						</div>
						<MAPrimaryButton
							intent="primary"
							fontSize={14}
							onClick={() => this.doSearch()}
							disabled={this.state.searchText === ""}
							title={"Search!"}
						>
							Search!
						</MAPrimaryButton>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						{this.showAboutButton()}
					</ContainerBox>
				</ContainerBox>
				<MAFooter />
			</ContainerBox>
		);
	}
}
