export class MANavigationItem {
	title: string;
	backTitle?: string;
	appSectionKey: string;
	popCompletionCallback?: () => void;
	extraData?: any;

	constructor(
		title: string,
		backTitle: string,
		appSectionKey: string,
		extraData?: any,
		popCompletionCallback?: () => void
	) {
		this.title = title;
		this.backTitle = backTitle !== "" ? backTitle : title;
		this.appSectionKey = appSectionKey;
		this.extraData = extraData;
		this.popCompletionCallback = popCompletionCallback;
	}

	getBackTitle() {
		const text: string = this.backTitle || "";
		return `${text.substring(0, 20)}${text.length > 20 ? "..." : ""}`;
	}

	getTitle() {
		const text: string = this.title || "";
		return `${text.substring(0, 20)}${text.length > 20 ? "..." : ""}`;
	}
}

export class MANavigationSet {
	previousItem?: MANavigationItem;
	currentItem: MANavigationItem;

	constructor(
		previousItem: MANavigationItem | undefined,
		currentItem: MANavigationItem
	) {
		this.previousItem = previousItem;
		this.currentItem = currentItem;
	}

	canGoBack(): boolean {
		return this.previousItem ? this.previousItem.appSectionKey !== "" : false;
	}
}
