import * as React from "react";

import { ContainerBox } from '../_lib/ContainerBox';
import { MALoadingView } from "../_lib/MALoadingView";
import * as TextStyles from "../_lib/TextStyles";
import styled from "styled-components";

interface IMACreditsState {
  isLoading: boolean;
}

const IMGContainer = styled.div`
  flex: 1;

  padding-bottom: 20px;
  text-align: center;
`;

export class MACreditsView extends React.Component<{}, IMACreditsState> {
  constructor(props: IMACreditsState) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  contentReady() {
    this.setState({
      isLoading: false,
    });
  }

  showLoading() {
    return <MALoadingView message={"Please wait, loading web site"} />;
  }

  images() {
    return (
      <ContainerBox direction={"column"}>
        <IMGContainer>
          <img
            src="./img/chorus_logo.png"
            alt={"Chorus logo"}
            style={{ width: "50%" }}
          />
        </IMGContainer>
        <IMGContainer>
          <img
            src="./img/gigcity_logo.png"
            alt={"Gig city logo"}
            style={{ width: "50%" }}
          />
        </IMGContainer>
        <IMGContainer>
          <img
            src="./img/applab_logo.png"
            alt={"AppLab logo"}
            style={{ width: "50%" }}
          />
        </IMGContainer>
        <IMGContainer>
          <img
            src="./img/uni_logo.png"
            alt={"AppLab logo"}
            style={{ width: "50%" }}
          />
        </IMGContainer>
      </ContainerBox>
    );
  }

  content() {
    const textColor: string = "#000000";
    const footNoteColor: string = "#4d4d4d";
    const baseSize: number = 22;
    return (
      <ContainerBox direction={"column"}>
        <TextStyles.TextParagraph color={textColor} fontSize={`${baseSize}px`}>
          The Te Reo Maaori word Tutuku is sourced from the Tainui Waikato
          dialect<sup>1</sup> meaning the ‘knowledge passed on’.
        </TextStyles.TextParagraph>
        <TextStyles.TextParagraph color={textColor} fontSize={`${baseSize}px`}>
          Tutuku brings together information from many different places into one
          single place or home.
        </TextStyles.TextParagraph>
        <TextStyles.TextParagraph color={textColor} fontSize={`${baseSize}px`}>
          It’s about connecting people with knowledge and histories of
          themselves.
        </TextStyles.TextParagraph>
       
        <br />
        <br />
        <TextStyles.TextParagraph
          color={footNoteColor}
          fontSize={`${baseSize - 4}px`}
        >
          <sup>1</sup>He Puna Kupu, He Manawa aa-whenua”, 2019) is the first
          ever Maaori dictionary of the Waikato-Tainui dialect
        </TextStyles.TextParagraph>
        <br />
        <br />
        <TextStyles.TextParagraph color={textColor} fontSize={`${baseSize}px`}>
          Copyright (2020) L Kewene-Doig tutukuinfo@gmail.com
        </TextStyles.TextParagraph>
        <br />
        <TextStyles.TextParagraph
          color={textColor}
          fontSize={`${baseSize - 2}px`}
        >
          <b>Thank you to these people:</b>
        </TextStyles.TextParagraph>
        <TextStyles.TextParagraph
          color={textColor}
          fontSize={`${baseSize - 2}px`}
        >
          GigCity Creative Communities Fund
          <br />
          Chorus
          <br />
          University of Otago
          <br />
          Audio Culture
          <br />
          Rim D. Paul
          <br />
          Khyla Russell
          <br />
          Grant Baxter
          <br />
          Heramaahina Eketone
          <br />
          Keanu Ager
          <br />
          Kaharau Keogh
          <br />
        </TextStyles.TextParagraph>
        <br />
        {this.images()}
      </ContainerBox>
    );
  }

  render() {
    return (
      <Container>
        <ContainerBox direction={"column"}>{this.content()}</ContainerBox>
      </Container>
    );
  }
}

const Container = styled.div`
  position: absolute;
  top: 160px;
  bottom: 20px;
  left: 10px;
  right: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  z-index: 99999;
  padding: 20px;
  overflow: auto;
`;
