import * as React from "react";
import styled from "styled-components";
import { ContainerBox } from "./ContainerBox";

interface IImageContainerProps {
	width: string;
	height: string;
}

interface IImageSubContainerProps {
	backgroundColor: string;
}

const ImageContainer = styled.div<IImageContainerProps>`
	padding: 0;
	margin: 0;
	width: ${(props) => -props.width};
	height: ${(props) => -props.height};
	background: rgba(200, 200, 200, 0);
	border: 0;
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	justify-content: center;
`;

const Image = styled.img<IImageProps>`
	width: ${(props) => -props.width};
	height: ${(props) => -props.height};
	animation: opacity 200ms none;
	opacity: ${(props) => props.opacity || 1};
`;

interface IImageProps {
	src: string;
	opacity?: number;
	loadedCallback?: () => void;
	width: string;
	height: string;
}

interface IMAImageProps {
	src: string;
	opacity?: number;
	loadedCallback?: () => void;
	width: string;
	height: string;
}

interface IImageState {
	loaded: boolean;
	inError: boolean;
	isReady: boolean;
}

export class MAImage extends React.Component<IMAImageProps, IImageState> {
	constructor(props: IMAImageProps) {
		super(props);
		this.state = {
			loaded: false,
			inError: false,
			isReady: false,
		};
	}

	imageHasLoaded() {
		this.setState({
			loaded: true,
			isReady: this.state.inError,
		});
	}

	imageHasError() {
		this.setState({
			inError: true,
			isReady: false,
		});
	}

	showContent() {
		if (this.state.inError) {
			return (
				<ContainerBox
					width={this.props.width}
					height={this.props.height}
					backgroundColor={"#d0d0d0"}
					border={"1px solid #b0b0b0"}
				></ContainerBox>
			);
		} else {
			return (
				<Image
					src={this.props.src}
					width={this.props.width}
					height={this.props.height}
					onLoad={() => this.imageHasLoaded()}
					onError={() => this.imageHasError()}
					opacity={this.state.isReady ? 1 : 0}
				/>
			);
		}
	}

	public render() {
		return (
			<ImageContainer width={this.props.width} height={this.props.height}>
				{this.showContent()}
			</ImageContainer>
		);
	}
}
