import * as React from 'react';
import Iframe from 'react-iframe';
import { MADataModel } from '../_lib/MADataModel';
import {MAContentContainer} from './MAContentContainer';
import {MALoadingView} from "../_lib/MALoadingView";

interface IMAWebContentViewState {
  isLoading:boolean,
}

interface IMAWebContentViewProps {
    extraData?: any,
}


export class MAWebContentView extends React.Component<IMAWebContentViewProps,IMAWebContentViewState> {

    constructor(props:IMAWebContentViewProps) {
        super(props);
        this.state={
            isLoading: true,
        };
    }

    contentReady() {
       this.setState({
           isLoading: false,
       });
    }

    showLoading() {
        return (
            <MALoadingView
                message={'Please wait, loading web site'}
            />
        );
    }

    getURL(): string {
        const extraData=MADataModel.sharedInstance().getCurrentAppNavigationItem().extraData;
        if (extraData) {
            if (extraData.hasOwnProperty('url')) {
                return extraData.url;
            }
        }
        return MADataModel.sharedInstance().selectedExternalURL;
    }


    render() {
        const urlString:string =this.getURL();
        return (
            <MAContentContainer>
                <Iframe url={urlString ? urlString : ''}
                    width="100%"
                    height="100%"
                    id="myId"
                    className="myClassname"
                    display="block"
                    position="relative"
                    onLoad={()=>this.contentReady()}
                />
                {this.state.isLoading ? this.showLoading() : null}
        </MAContentContainer>

        );
    }
}

