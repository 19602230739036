import * as React from 'react';
import YouTube from 'react-youtube';
import Iframe from 'react-iframe'

import { MADataModel } from '../_lib/MADataModel';
import { ContainerBox } from '../_lib/ContainerBox';
import {ExternalItem} from '../_lib/Item/ExternalItem';
import { MADigitalNZItemView } from './MADigitalNZItemView';
import {MAContentContainer} from './MAContentContainer';
import {MALoadingView} from "../_lib/MALoadingView";
import * as TextStyles from "../_lib/TextStyles";

interface IExternalItemViewProps {
    url?: any;
}

interface IExternalItemViewState {
    isLoading: boolean;
}

export class MAExternalItemView extends React.Component<IExternalItemViewProps,IExternalItemViewState> {

    constructor(props: IExternalItemViewProps) {
        super(props);
        this.state={
            isLoading: true,
        };
    }


    contentReady() {
       this.setState({
           isLoading: false
       });
    }

    renderWebContent(urlString:string | undefined) {
        // MADataModel.sharedInstance().setLoadingViewVisibility(true);
        return (
           <MAContentContainer>
              <Iframe url={urlString ? urlString : ''}
                width="100%"
                height="100%"
                id="myId"
                className="myClassname"
                display="block"
                position="relative"
                onLoad={()=>this.contentReady()}
            />
        </MAContentContainer>
        );
    }

    renderYouTube(item:ExternalItem) {
    
        const mediaID=item ? item.valueForExtraField('media_id') : '';
        const parser = new DOMParser();
        const decodedString = parser.parseFromString(item.getTitle(),'text/html').body.textContent;
        return (
            <ContainerBox direction={'column'}>
                <TextStyles.TextParagraph
                    color={'white'}
                    fontSize={'18px'}
                >
                    {decodedString}
                </TextStyles.TextParagraph>
                <br />
                <YouTube
                    videoId={mediaID}
                    id={mediaID}
                    onReady={()=>this.contentReady()}
                />
            </ContainerBox>
        );
    }

    renderDigitalNZ(digitalNZItem:ExternalItem) {
        return (
            <MAContentContainer>
                <MADigitalNZItemView
                    item={digitalNZItem}
                />
            </MAContentContainer>
        );
    }


   showLoading() {
        return (
            <MALoadingView
                message={'Please wait, loading web site'}
            />
        );
   }
    
    render() {
        const item: ExternalItem | undefined = MADataModel.sharedInstance().selectedItem as ExternalItem;
        const isLoading=item.externalSourceKey!=='MADigitalNZSearch' && this.state.isLoading;
        const urlString:string | undefined=item ? item.getURLForItem() : undefined;
        let content;
        if (item.externalSourceKey==='MAYouTubeSearch') {
            content=this.renderYouTube(item);
        }
        else if (item.externalSourceKey==='MADigitalNZSearch') {
            content=this.renderDigitalNZ(item);
        }
        else {
            content=this.renderWebContent(urlString);
        }
        return (
            <ContainerBox direction={'column'}>
                {content}
                {
                    isLoading ? this.showLoading() : null
                }
            </ContainerBox>
        );
    }
}

