import * as React from 'react';
import styled from 'styled-components';
import {TextParagraph} from './TextStyles';
import { ContainerBox } from './ContainerBox';
import { MAPrimaryButton } from '../_lib/MAButton';

const Container=styled.div`
    position: fixed;
    padding: 10px;
    margin: 0;
    top: 0px;
    left: 0px;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,1.0);
    z-index: 19999;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
`;

const LoadingImage=styled.img`
    width: 250px;
    height: 250px;
`;

// const kLoadingViewTimeout=5000;

export type LoadingViewCallbackT=(wasError:boolean)=>void;


interface ILoadingViewState {
    loadFailed: boolean;
}

interface ILoadingViewProps {
    message?: string;
    timeout?: number;
    callback?: (wasError:boolean)=>void;
}

export class MALoadingView extends React.Component<ILoadingViewProps,ILoadingViewState> {

    timeoutID:number;

    constructor(props:ILoadingViewProps) {
        super(props);
        this.timeoutID=-1;
        this.state={
            loadFailed:false
        };
    }

    setLoadTimedout() {
        clearTimeout(this.timeoutID);
        this.setState({
            loadFailed:true
        });
    }

    private dismiss(wasError:boolean) {
        if (this.props.callback) {
            this.props.callback(wasError);
        }
    }


    private showError() {
        return(
            <ContainerBox direction={'column'}>
                <TextParagraph color='#f0f0f0' fontSize={'24px'} alignment={'center'}>
                   Whoops
                </TextParagraph>
                <TextParagraph color='#f0f0f0' fontSize={'18px'} alignment={'center'}>
                   An error occurred while loading that content, try again later?
                </TextParagraph>
                <br />
                <br />
                <br />
                {
                    this.props.callback ?
                    <MAPrimaryButton 
                            intent={'secondary'} 
                            fontSize={14}
                            onClick={()=>this.dismiss(true)}
                        >
                        Continue
                    </MAPrimaryButton> 
                    : null
                }
            </ContainerBox>
        );
    }

    private showContent() {
        return (
            <ContainerBox direction={'column'}>
                 <LoadingImage src="./img/loading_animation.gif" alt={"Loading animated image"} />
                <TextParagraph color='#f0f0f0' fontSize={'18px'} alignment={'center'}>
                    {this.props.message || 'Please wait, loading...'}
                </TextParagraph>
                <br />
                <br />
                <br />
                <MAPrimaryButton 
                    intent={'secondary'}
                    fontSize={18}
                    onClick={()=>this.dismiss(false)}
                >
                    Cancel
                </MAPrimaryButton> 
            </ContainerBox>
        );
    }

   

    public render() {
        // if (this.timeoutID===-1) {
        //     setTimeout(()=>this.setLoadTimedout(),kLoadingViewTimeout);
        // }
        return (
           <Container>
                {this.state.loadFailed ? this.showError() : this.showContent()}
           </Container>
         
          
        );
    }
}

