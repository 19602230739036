
import moment, { Moment } from 'moment';
import AppGlobal from '../AppGlobal';


type MediaType = 'image' | 'audio' | 'movie';

export class MediaItem  {

    added_by_user_id: number | undefined;
    created_at: Moment | undefined;
    description: string | undefined;
    filepath: string | undefined;
    filesize: number | undefined;
    id: number;
    item_id: number | undefined;
    keywords: string | undefined;
    metadata: string | undefined;
    name: string | undefined;
    originalextension: string | undefined;
    originalfilename: string | undefined;
    originalheight: number | undefined;
    originalmimetype: string ;
    originalwidth: number | undefined;
    slug: string | undefined;
    type: string | undefined;
    updated_at: Moment | undefined;
    urlToResource: string | undefined;

    constructor(fields:any={}) {
        this.added_by_user_id=fields.added_by_user_id || AppGlobal.kNullNUmberSentinel;
        this.created_at= moment(fields.created_at || '', AppGlobal.kDefaultDateFormat);
        this.description=fields.description || undefined;
        this.filepath=fields.filepath || undefined;
        this.filesize=fields.filesize || AppGlobal.kNullNUmberSentinel;
        this.id= fields.id || AppGlobal.kNullNUmberSentinel;
        this.item_id=fields.item_id || AppGlobal.kNullNUmberSentinel;
        this.keywords=fields.keywords || '';
        this.metadata=fields.metadata || '';
        this.name=fields.name || '';
        this.originalextension=fields.originalextension || '';
        this.originalfilename=fields.originalfilename || '';
        this.originalheight=fields.originalheight || AppGlobal.kNullNUmberSentinel;
        this.originalmimetype=fields.originalmimetype || '';
        this.originalwidth = fields.originalwidth || AppGlobal.kNullNUmberSentinel;
        this.slug = fields.slug || undefined;
        this.type = fields.type || undefined;
        this.updated_at = moment(fields.updated_at  || '', AppGlobal.kDefaultDateFormat);
        this.urlToResource = fields.urlToResource || undefined;
    }

    getType():MediaType {
        if (this.originalmimetype.indexOf('image/')>=0) {
            return 'image';
        }
         if (this.originalmimetype.indexOf('audio/')>=0) {
            return 'image';
         }
         if (this.originalmimetype.indexOf('video/')>=0) {
            return 'image';
        }
        return 'image';
    }


    getThumbnailURL():string {
        return `/img/media/.png`;
    }

}
