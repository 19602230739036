import * as React from "react";
import styled from "styled-components";

import { MADataModel } from "../_lib/MADataModel";
import { ContainerBox } from "../_lib/ContainerBox";
import { NativeItem } from "../_lib/Item/NativeItem";
import * as TextStyles from "../_lib/TextStyles";
import { MAPrimaryButton } from "../_lib/MAButton";
import { MAContentContainer } from "./MAContentContainer";
import { Media } from "reactstrap";
import { MediaItem } from "../_lib/Item/MediaItem";
import { MANavigationItem } from "../_lib/MANavigationItem";

interface INativeItemViewProps {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-color: white;
  border: 1px solid #4d4d4d;
  border-radius: 4px;
  padding: 20px;
  overflow: auto;
`;

const Thumbnail = styled.img`
  border-radius: 14px;
  border: 1px solid #2d2d2d;
  background-color: transparent;
  margin-bottom: 12px;
`;

const ThumbnailButton = styled.button`
  border: none;
  background-color: transparent;
  &:focus {
    border: none;
    outline: none;
  }
`;

export class MANativeItemView extends React.Component<INativeItemViewProps,{}> {
  formattedStringField(descriptionTextOrNull: string | undefined) {
    if (descriptionTextOrNull) {
      const lines = descriptionTextOrNull.split(". ");
      return (
        <div>
          {lines.map((content: string, index: number) => {
            return (
              <TextStyles.TextParagraph key={`line-#{index}`}>
                {content}
                {index < lines.length - 1 ? "." : ""}
              </TextStyles.TextParagraph>
            );
          })}
        </div>
      );
    }
    return <div />;
  }

  itemField(data: any, propName: string) {
    const contentString = data[propName];
    return (
      <NativeItemFieldContainer>
        <ContainerBox
          direction={"column"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          <TextStyles.TextParagraph color={"#8d8d8d"} fontSize={"14px"}>
            {propName}
          </TextStyles.TextParagraph>
          <TextStyles.TextParagraph
            color={"#1d1d1d"}
            fontSize={"16px"}
            alignment={"left"}
          >
            {this.formattedStringField(contentString)}
          </TextStyles.TextParagraph>
        </ContainerBox>
      </NativeItemFieldContainer>
    );
  }

  dataToDisplay(item: NativeItem) {
    return {
      Title: item.title,
      Category: item.category,
      Description: item.description,
      Copyright: item.copyright,
      "Source URL": item.sourceURL,
      License: item.license,
      Publisher: item.publisher,
      "Syndication date": item.syndicationDate,
      "Display date": item.displayDate,
      Author: item.author,
      Date: item.date,
      "Content partner": item.contentPartner,
      Thumbnail: item.thumbnailURL,
      URL: item.sourceURL,
    };
  }

  showSource(item: NativeItem) {
    window.open(item.sourceURL, "_blank");
  }

  showLinkButtonOrNull(data: any, item: NativeItem | undefined) {
    if (item && item.sourceURL) {
      return (
        <MAPrimaryButton
          intent={"primary"}
          fontSize={18}
          onClick={() => this.showSource(item)}
        >
          View original content
        </MAPrimaryButton>
      );
    }
    return null;
  }

    mediaWasClicked(mediaItem: MediaItem) {

    MADataModel.sharedInstance().setSelectedIImageViewerImageURL(mediaItem);
    const newNavItem = new MANavigationItem("Image", "Item", "imageViewer");
    MADataModel.sharedInstance().pushAppNavigationStack(newNavItem);
  }

  showMedia(item: NativeItem) {
    const media = item.media || [];
    return media.map((mediaItem: MediaItem, index: number) => {
      const key = `media-${mediaItem.id}`;
      return (
        <ThumbnailButton
          key={key}
          onClick={() => this.mediaWasClicked(mediaItem)}
        >
          <Thumbnail
            src={MADataModel.sharedInstance().urlForMediaThumbnailWithID(
              mediaItem.id
            )}
            alt={"media_thumbnail"}
          />
        </ThumbnailButton>
      );
    });
  }

  render() {
    const item: NativeItem | undefined = MADataModel.sharedInstance()
      .selectedItem as NativeItem;

    let data = this.dataToDisplay(item);
    return (
      <MAContentContainer bottomOffset={60}>
        <Container>
          {this.itemField(data, "Title")}
          {this.itemField(data, "Category")}
          {this.itemField(data, "Description")}
          {this.showMedia(item)}
          {this.itemField(data, "Copyright")}
          {this.itemField(data, "Source URL")}
          {this.itemField(data, "License")}
          {this.itemField(data, "Publisher")}
          {this.itemField(data, "Syndication date")}
          {this.itemField(data, "Display date")}
          {this.itemField(data, "Author")}
          {this.itemField(data, "Content partner")}

          {this.showLinkButtonOrNull(data, item)}
        </Container>
      </MAContentContainer>
    );
  }
}

const NativeItemFieldContainer = styled.div`
  padding-bottom: 20px;
`;
