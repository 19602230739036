import styled from "styled-components";

interface MAContentContainerPropsI {
	bottomOffset?: number;
	backgroundColor?: string;
	borderRadius?: string;
	border?: string;
}

function test(num: number | undefined): number {
	return num ? num : 20;
}

export const MAContentContainer = styled.div<MAContentContainerPropsI>`
	position: absolute;
	right: 20px;
	left: 20px;
	top: 170px;
	bottom: ${(props) => test(props.bottomOffset)}px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
	padding: 0 20px 0 20px;
	background-color: ${(props) => props.backgroundColor || "transparent"};
	border-radius: ${(props) => props.borderRadius || "0"};
	border: ${(props) => props.border || "0"};
`;
