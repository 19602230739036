import React from 'react';
import styled from 'styled-components';
import Fullscreen from "react-full-screen";
import {observer} from 'mobx-react';

import { MADataModel } from './_lib/MADataModel';
import {MANavigationSet} from './_lib/MANavigationItem';
import { MAHomePage } from './pages/MAHomePage';
import { MAResultsPage } from './pages/MAResultsPage';
import { MALoadingView } from './_lib/MALoadingView';
// import { MAErrorView } from './_lib/MAErrorView';
import { MAExternalItemView } from './pages/MAExternalItemView';
import { MANativeItemView } from './pages/MANativeItemView';
import { MAPageHeader } from './pages/MAPageHeader';
import { MACreditsView } from './pages/MACreditsView';
import {MAWebContentView} from './pages/MAWebContentView';
import { MAImageViewer } from './pages/MAImageViewer';

const MasterContainer=styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left:0px;
  right: 0px;
  bottom:0px;
  top:0px;
  background-color: #996b24;
`;

const ContentContainer=styled.div`
  flex:10;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color:transparent;
`;


@observer class MARoot extends React.Component {

  hideError() {
  }

  showError() {
    return undefined;
    // return (<MAErrorView  />);
  }

  loadingViewCallback(wasCancelled:boolean) {
    MADataModel.sharedInstance().loadingViewCallback!(wasCancelled)
  }

  showLoading() {
    return (
        <MALoadingView callback={(wasCancelled:boolean)=>this.loadingViewCallback(wasCancelled)}/>
    );
  }


  renderPage() {
    const currentNavSet:MANavigationSet=MADataModel.sharedInstance().currentAppNavigationSet;
      switch (currentNavSet.currentItem.appSectionKey) {
        case 'home': {
          return (<MAHomePage />);
        }
        case 'results': {
          return (<MAResultsPage />);
        }
        case 'externalitem': {
          return (<MAExternalItemView />);
        }
        case 'nativeitem': {
          return (<MANativeItemView />);
        }
        case 'credits': {
          return (<MACreditsView />);
        }
        case 'native_source': {
          return (<MAWebContentView />);
        }
        case 'webview': {
          return (<MAWebContentView />);
        }
           case 'imageViewer': {
          return (<MAImageViewer />);
        }
       default: {
          return (<MAHomePage />);
        }
      }
  }


  render() {
    return (
        <Fullscreen enabled={MADataModel.sharedInstance().isInFullScreen}>
          <MasterContainer>

            {
              MADataModel.sharedInstance().dataIsLoading ? this.showLoading() : null
            }
            {
              MADataModel.sharedInstance().isInError ? this.showError() : undefined
            }
             <MAPageHeader/>
             <ContentContainer>
                {
                  this.renderPage()
                }
            </ContentContainer>
          </MasterContainer>
        </Fullscreen>
    );
  }
}


export {MARoot};
