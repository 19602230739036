import { Moment } from 'moment';
import AppGlobal from '../AppGlobal';

export class CoreItem {
    itemClass:string;
    itemType:string;
    _extraFields:{ [key: string]: any };
    author:string;
    altDescription:string;
    category:string;
    contentPartner:string;
    copyright: string;
    date:Moment;
    description: string;
    displayDate: string;
    id: number;
    license: string;
    locationString: string;
    publisher: string;
    sourceURL: string;
    syndicationDate: string;
    thumbnailURL: string;
    title: string;
    keywords: string;
    mediaType: string;

    constructor(itemClass:string, fields:any) {
        this.itemClass=itemClass;
        this.itemType=fields.itemType || '';
        this._extraFields=fields._extraFields || {};
        this.author=fields.author || '';
        this.altDescription=fields.alt_description || '';
        this.category=fields.category || '';
        this.contentPartner=fields.contentPartner || '';
        this.copyright=fields.copyright || '';
        this.description=fields.description || '';
        this.date=fields.date || '';
        this.displayDate=fields.displayDate || '';
        this.id=fields.id || AppGlobal.kNullNUmberSentinel;
        this.license=fields.license || '';
        this.locationString=fields.location || '';
        this.publisher=fields.publisher || '';
        this.sourceURL=fields.sourceURL || '';
        this.syndicationDate=fields.syndication_date || '';
        this.thumbnailURL=fields.thumbnailURL || '';
        this.title=fields.title || '';
        this.keywords=fields.keywords || '';
        this.mediaType='Generic';
    }

    getThumbnailURL():string {
        return this.thumbnailURL;
    }

    shortDescription():string {
        const showMore=this.description.length>255;
        return this.description.substring(0,255)+(showMore ? '...':'');
    }

    getTitle():string {
        const showMore=this.title.length>125;
        return this.title.substring(0,125)+(showMore ? '...':'');
    }

    getCategory(): string {
        return this.category==='' ? 'generic' : this.category;
    }

    valueForExtraField(fieldKey:string): any {
        if (this._extraFields) {
            if (this._extraFields[fieldKey]) {
                return this._extraFields[fieldKey];
            }
        }
       return undefined;
    }

    getURLForItem():string | undefined {
        return 'CS';
    }



}

