import React from 'react';
import './App.css';
import {MARoot} from './MARoot';





const App: React.FC = () => {
  return (
      <MARoot>
      </MARoot>
  );
}

export default App;
