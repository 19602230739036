import {CoreItem} from './CoreItem';


export class ExternalItem extends CoreItem {

    itemClass:string;
    externalSourceKey:string;
    itemType:string;
    _extraFields:string[];
    author:string;
    altDescription:string;
    category:string;
    contentPartner:string;
    copyright: string;
    description: string;
    displayDate: string;
    id:number;
    license: string;
    location: string;
    publisher: string;
    sourceURL: string;
    syndicationDate: string;
    thumbnailURL: string;
    title: string;



    constructor(fields:any,externalSourceKey: string) {
        super('external',fields);
        this.itemClass='external';
        this.externalSourceKey=externalSourceKey;
        this.itemType=fields.itemType || '';
        this._extraFields=fields._extraFields || [];
        this.author=fields.author || '';
        this.altDescription=fields.alt_description || '';
        this.category=fields.category || '';
        this.contentPartner=fields.contentPartner || '';
        this.copyright=fields.copyright || '';
        this.description=fields.description || '';
        this.date=fields.date || new Date();
        this.displayDate=fields.displayDate || '';
        this.id=fields.id || -1;
        this.license=fields.license || '';
        this.location=fields.location || '';
        this.publisher=fields.publisher || '';
        this.sourceURL=fields.sourceURL || '';
        this.syndicationDate=fields.syndication_date || '';
        this.thumbnailURL=this.determineThumbnailURLFromFields(fields);
        this.title=fields.title || '';
    }

    private determineThumbnailURLFromFields(fields: any): string {
        //Check for youTube
        if (fields._thumbnailURL) {
            return fields._thumbnailURL
        }
        //Check DigitalNZ
        if (fields.thumbnail_url) {
            return fields.thumbnail_url;
        }
        return '';
    }


    getThumbnailURL():string {
        if (this.thumbnailURL!=='') {
            return this.thumbnailURL;
        }
        else {
            return `/img/media/${this.externalSourceKey}.png`;
        }
    }

    getCategory(): string {
        switch (this.externalSourceKey) {
            case '': {
                return super.getCategory();
            }
            case 'MAYouTubeSearch': {
                return 'video';
            }
            case 'MADuckDuckGoSearch': {
                return 'URL';
            }
            case 'MADigitalNZSearch': {
                return 'mixed media';
            }
            case 'MAWikipediaSearch': {
                return 'Webpage';
            }
            default: {
                return super.getCategory();
            }
        }
    }


    /*


-(NSString *)urlForYouTubeItem {
	
	NSString *urlString;
	NSString *kind=[self extraFieldStringValue:@"kind"];
	if ([kind isEqualToString:@"youtube#video"]) {
		NSString *videoId=[self extraFieldStringValue:@"media_id"];
		urlString=[NSString stringWithFormat:@"https://www.youtube.com/watch?v=%@",videoId];
	}
	else if ([kind isEqualToString:@"youtube#playlist"]) {
		NSString *playlistId=[self extraFieldStringValue:@"media_id"];
		urlString=[NSString stringWithFormat:@"https://www.youtube.com/watch?v=%@",playlistId];
	}
	else {
		urlString=nil;
	}
	return urlString;
}

    */

    private urlForWikipediaItem():string | undefined {
        const pageID=this.valueForExtraField('pageid');
        return pageID ? `https://en.m.wikipedia.org/?curid=${pageID}` : undefined;
    }


    private urlForDuckDuckGoItem():string | undefined {
        let url=this.valueForExtraField('url');
        if (url==='') {
            const all=this.valueForExtraField('all') as any;
            url=all.FirstURL;
        }
        return url || undefined;
    }

    getYouTubeMediaID() {
        const kind=this.valueForExtraField('kind');
        if (kind==='youtube#video') {
            return this.valueForExtraField('media_id');
        }
        else if (kind==='youtube#playlist') {
            return this.valueForExtraField('media_id');
        }
    }

    private urlForYouTubeItem():string | undefined {
        const kind=this.valueForExtraField('kind');
        if (kind==='youtube#video') {
            const videoId=this.valueForExtraField('media_id');
            if (videoId) {
                return `https://www.youtube.com/watch?v=${videoId}`;
            }
        }
        else if (kind==='youtube#playlist') {
            const playlistID=this.valueForExtraField('media_id');
            if (playlistID) {
                return `https://www.youtube.com/watch?v=${playlistID}&list=${playlistID}`;
            }
        }
        return  undefined;
    }

    private urlForDigitalNZItem(): string | undefined {
        return undefined;
    }

  
    getURLForItem():string | undefined {
        if (this.externalSourceKey==='MADuckDuckGoSearch') {
            return this.urlForDuckDuckGoItem();
        }
        else if (this.externalSourceKey==='MAWikipediaSearch') {
            return this.urlForWikipediaItem();
        }
        else if (this.externalSourceKey==='MAYouTubeSearch') {
            return this.urlForYouTubeItem();
        }
        else if (this.externalSourceKey==='MADigitalNZSearch') {
            return this.urlForDigitalNZItem();
        }
        return undefined;
    }


}

