import styled from 'styled-components';

interface IMABadgeProps {
    color: string;
}

export const MABadge=styled.div<IMABadgeProps>`
    display: inline-block;
    border-radius: 6px;
    padding: 4px 6px 4px 6px;
    line-height: 1em;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    border: 1px solid ${props=>props.color};
    color: ${props=>props.color};
    background-color: transparent;
    font-variant: small-caps;
`;


