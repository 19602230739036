import { MADataModel } from "./MADataModel";

const superagent = require("superagent");

function uuidv4(): string {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c === "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export type ALAJAXCallback = (responseObject: ALAJAXResponse) => void;

export class ALAJAXStatus {
	code: number;
	message: string;

	constructor(errCode: number, message: string) {
		this.code = errCode;
		this.message = errCode > 0 ? `Error code ${errCode}` : "";
	}

	static okStatus() {
		return new ALAJAXStatus(0, "OK");
	}

	isError(): boolean {
		return this.code > 0;
	}
}

export class ALAJAXResponse {
	payload: Object;
	status: ALAJAXStatus;

	constructor(responseObject: any, status: ALAJAXStatus) {
		this.payload = responseObject.payload;
		this.status = status;
	}
}

export class ALAjaxRequest {
	static numRequests: number = 0;
	static requests: string[] = [];

	identifier: string;
	action: string;
	method: string;
	dataType: string;
	contentType: string;
	baseURL: string;
	serverAddress: string;
	sentData: any;
	callback: ALAJAXCallback | null;
	jsonifyData: boolean;
	response: ALAJAXResponse | null;
	status: ALAJAXStatus | null;

	constructor(action: string, sentData: Object, callback: ALAJAXCallback) {
		this.identifier = uuidv4();
		this.action = action;
		this.sentData = sentData;
		this.method = "POST";
		this.dataType = "json";
		this.contentType = "application/json; charset=utf-8";
		this.serverAddress = MADataModel.sharedInstance().cloudServerAddress();
		this.baseURL = "/api/";
		this.callback = callback;
		this.jsonifyData = true;
		this.response = null;
		//First, inject the web token into the sent data
		this.sentData = Object.assign(this.sentData, {
			requestToken: this.requestToken(),
		});
		this.status = null;
	}

	abort() {}

	requestToken(): string {
		return "token";
		// return "AHr1sq6W3gr5Z7HJxFU0F5yONZFNagNt9Cx8i1o8rp9aOguVvxPGZGWqGMvA3qengLseEvSgjRqOmxRapoXMTWmhX5ggS0rae6EJLeGvsJdzgfG0QQWYW9yham7slD2wPNZ0CUNROMrwhl0F1kf3FwmdtmreiBgt6crlNpPBp6q3qLlar49kKFNNpsi2WSnvTsTdNEnmrfsm2L1jSFC5i3ntxc8KOi3jaqdr9VA2ydxMJeBheRJTPJy22fQy2T7b";
	}

	requestURL(): string {
		return (
			this.serverAddress +
			this.baseURL +
			this.action +
			"?time=" +
			Math.random() * 1000000
		);
	}

	getPayload(objectData: any) {
		if (objectData.hasOwnProperty("data")) {
			return objectData.data;
		} else {
			return null;
		}
	}

	requestComplete(err: number, rawResponseObject: any) {
		try {
			let responseObject = JSON.parse(rawResponseObject.text);
			let response = new ALAJAXResponse(
				responseObject,
				ALAJAXStatus.okStatus()
			);
			if (this.callback) {
				this.callback(response);
			}
		} catch (e) {
			console.log("Exception caught: JSON parse error");
			let status = new ALAJAXStatus(3, "JSON parse error");
			let dummyObject = {
				payload: {},
			};
			let response = new ALAJAXResponse(dummyObject, status);
			if (this.callback) {
				this.callback(response);
			}
		}
	}

	send() {
		this.sentData.requestToken = "token";
		superagent
			.post(this.requestURL())
			.send(this.sentData)
			.set("X-API-Key", "foobar")
			.set("accept", "json")
			.end((err: number, res: any) => {
				this.status = new ALAJAXStatus(err, "Error sending ajax request");
				this.requestComplete(err, res);
			});
	}
}
