import React, { Component } from "react";
import styled from "styled-components";

interface ISearchFieldContainerProps {
	fontSize?: string;
	width?: string;
}

const SearchFieldContainer = styled.div<ISearchFieldContainerProps>`
	position: relative;
	color: #aaa;
	font-size: ${(props) => (props.fontSize ? props.fontSize + "px" : "20px")};
	width: ${(props) => (props.width ? props.width : "200px")};
`;

interface ISearchFieldInputProps {
	fontSize?: string;
	backgroundColor?: string;
	borderColor?: string;
}

const SearchFieldInput = styled.input<ISearchFieldInputProps>`
	background-color: ${(props) => props.backgroundColor || "#ffffff"};
	border: 1px solid ${(props) => props.borderColor || "silver"};
	border-radius: 6px;
	float: left;
	color: ${(props) => props.color || "b7b7b7"};
	padding-left: 28px;
	padding-right: 25px;
	font-size: ${(props) => (props.fontSize ? props.fontSize + "px" : "20px")};
	width: ${(props) => (props.width ? props.width : "300px")};
`;

interface ISearchFieldICONProps {
	fontSize?: string;
}

const SearchFieldICON = styled.i<ISearchFieldICONProps>`
	position: absolute;
	left: 8px;
	top: 2px;
	z-index: 1;
	color: silver;
	font-size: ${(props) =>
		props.fontSize ? props.fontSize + 2 + "px" : "20px"};
`;

interface ISearchFieldClearButtonProps {
	fontSize?: string;
}

const SearchFieldClearButton = styled.button<ISearchFieldClearButtonProps>`
	position: absolute;
	right: 2px;
	top: 2px;
	font-size: ${(props) => (props.fontSize ? props.fontSize + "px" : "20px")};
	cursor: pointer;
	color: #ccc;
	border: 0;
	background-color: transparent;
	padding: 0;
	margin: 0;
	width: 20px;
`;

interface IALSearchFieldProps {
	searchValue: string;
	width?: string;
	placeholderText?: string;
	border?: string;
	fillColor?: string;
	textColor?: string;
	placeholderTextColor?: string;
	changeCallback: (newValue: string, clear: boolean) => void;
	commitCallback?: (newValue: string) => void;
}

interface IALSearchFieldState {
	searchValue: string;
}

export default class ALSearchField extends Component<
	IALSearchFieldProps,
	IALSearchFieldState
> {
	constructor(props: IALSearchFieldProps) {
		super(props);
		this.state = {
			searchValue: props.searchValue,
		};
		this.resetSearchValue = this.resetSearchValue.bind(this);
		this.searchFieldValueDidChange = this.searchFieldValueDidChange.bind(this);
		this.lookForSpecialKeys = this.lookForSpecialKeys.bind(this);
	}

	resetSearchValue() {
		this.setState({
			searchValue: "",
		});
		if (this.props.changeCallback) {
			this.props.changeCallback("", true);
		}
	}

	searchFieldValueDidChange(e: any) {
		this.setState({
			searchValue: e.target.value,
		});
		if (this.props.changeCallback) {
			this.props.changeCallback(e.target.value, false);
		}
	}

	lookForSpecialKeys(e: any) {
		if (e.keyCode === 27) {
			// escape key maps to keycode `27`
			this.resetSearchValue();
		}
		if (e.keyCode === 13) {
			// return key maps to keycode `13`
			if (this.props.commitCallback) {
				this.props.commitCallback(this.state.searchValue);
			}
		}
	}

	render() {
		let textColor;
		if (this.state.searchValue !== "") {
			textColor = this.props.textColor || "#4d4d4d";
		} else {
			textColor = this.props.placeholderTextColor || "#c0c0c0";
		}
		return (
			<SearchFieldContainer fontSize={"12px"} width={this.props.width}>
				<SearchFieldInput
					width={this.props.width}
					type={"text"}
					placeholder={this.props.placeholderText}
					value={this.state.searchValue}
					onChange={this.searchFieldValueDidChange}
					onKeyUp={this.lookForSpecialKeys}
					backgroundColor={this.props.fillColor || "white"}
					borderColor={this.props.border || "#c0c0c0"}
					color={textColor}
				/>
				<SearchFieldICON>
					<i className={"lgi lgi-search"}></i>{" "}
				</SearchFieldICON>
				{this.state.searchValue !== "" ? (
					<SearchFieldClearButton onClick={this.resetSearchValue}>
						<i className={"lgi lgi-cross"}></i>
					</SearchFieldClearButton>
				) : (
					""
				)}
			</SearchFieldContainer>
		);
	}
}
