import { observable, action } from 'mobx';
import { ALAjaxRequest, ALAJAXStatus, ALAJAXCallback } from './ALAjaxRequest';
import { ExternalItem } from './Item/ExternalItem';
import { MediaItem } from './Item/MediaItem';
import { NativeItem } from './Item/NativeItem';
import { CoreItem } from './Item/CoreItem';
import { MANavigationSet, MANavigationItem } from './MANavigationItem';
import { LoadingViewCallbackT } from './MALoadingView';

export interface SearchResultSetI {
  external_results: ExternalItem[];
  media_results: MediaItem[];
  native_results: NativeItem[];
}

type NavigationPopCallback = () => void;

class MADataModel {
  static instance: MADataModel | null = null;

  static serverAddress: string = '';
  static APIKey = '123-456-789';
  static rootURL: string = '/api/';
  static inDevNode: boolean = true;

  static MABackgroundColor: string = 'rgb(170,73,45)';

  @observable isInFullScreen: boolean;

  @observable windowWidth: number;
  @observable windowHeight: number;

  appNavigationStack: MANavigationSet[];
  @observable currentAppNavigationSet: MANavigationSet;

  @observable dataIsLoading: boolean = false;
  loadingViewCallback: LoadingViewCallbackT | undefined;

  @observable searchResults: SearchResultSetI;
  numberOfSearchResults: number;
  @observable isInError: boolean = false;

  searchValue: string | undefined;
  selectedItem: CoreItem | undefined;
  selectedExternalURL: string;
  searchCompleted: boolean = false;
  selectedMediaItem: MediaItem | undefined = undefined;

  errorMessage: string = '';

  static sharedInstance(): MADataModel {
    if (!MADataModel.instance) {
      MADataModel.instance = new MADataModel();
    }
    return MADataModel.instance;
  }

  constructor() {
    this.appNavigationStack = [];
    this.currentAppNavigationSet = new MANavigationSet(
      undefined,
      new MANavigationItem('Search', 'Home', 'home')
    );
    this.appNavigationStack.push(this.currentAppNavigationSet);
    this.searchValue = '';
    this.dataIsLoading = false;
    this.searchValue = undefined;
    this.searchResults = this.getEmptySearchResults();
    this.searchCompleted = false;
    this.isInError = false;
    this.errorMessage = '';
    this.selectedItem = undefined;
    this.windowWidth = 0;
    this.windowHeight = 0;
    this.selectedExternalURL = '';
    this.selectedMediaItem = undefined;
    this.loadingViewCallback = undefined;
    this.numberOfSearchResults = 0;
    this.isInFullScreen = false;
    window.addEventListener('resize', () => this.updateWindowDimensions());
    this.updateWindowDimensions();
  }

  @action toggleFullScreen() {
    this.isInFullScreen = !this.isInFullScreen;
  }

  updateWindowDimensions() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  appNavigationAtRoot(): boolean {
    return this.appNavigationStack.length === 1;
  }

  getCurrentAppNavigationItem(): MANavigationItem {
    return this.currentAppNavigationSet.currentItem;
  }

  updateCurrentAppSection() {
    this.currentAppNavigationSet =
      this.appNavigationStack[this.appNavigationStack.length - 1];
  }

  popAppNavigationStack(completionCallback?: NavigationPopCallback): void {
    if (this.appNavigationStack.length > 1) {
      this.appNavigationStack.pop();
      this.updateCurrentAppSection();
    }
    if (completionCallback) {
      completionCallback();
    }
  }

  pushAppNavigationStack(newNavigationItem: MANavigationItem) {
    const newNavSet = new MANavigationSet(
      this.currentAppNavigationSet.currentItem,
      newNavigationItem
    );
    this.appNavigationStack.push(newNavSet);
    this.updateCurrentAppSection();
  }

  setSearchValue(newText: string) {
    this.searchValue = newText;
  }

  getSearchValue(): string | undefined {
    return this.searchValue;
  }

  getEmptySearchResults() {
    return {
      external_results: [],
      media_results: [],
      native_results: [],
    };
  }

  setSelectedItem(item: CoreItem) {
    this.selectedItem = item;
  }

  setSelectedIImageViewerImageURL(selectedMediaItem: MediaItem) {
    this.selectedMediaItem = selectedMediaItem;
  }

  processExternalResultsSubType(
    externalSourceKey: string,
    resultsArray: any[]
  ) {
    resultsArray.map((itemObject, index) => {
      let newItem = new ExternalItem(itemObject, externalSourceKey);
      if (externalSourceKey === 'MAYouTubeSearch') {
        if (newItem.valueForExtraField('kind') === 'youtube#playlist') {
          return undefined;
        }
      }
      if (externalSourceKey !== 'MADuckDuckGoSearch') {
        this.searchResults.external_results.push(newItem);
        this.numberOfSearchResults++;
      }
      return undefined;
    });
  }

  processExternalResults(externalResultsObject: any[]) {
    for (var prop in externalResultsObject) {
      if (Object.prototype.hasOwnProperty.call(externalResultsObject, prop)) {
        //prop contains the external search engine key...
        this.processExternalResultsSubType(prop, externalResultsObject[prop]);
      }
    }
  }

  processMediaResults(mediaResultsArray: any[]) {
    mediaResultsArray.map((itemObject: any, index) => {
      let newItem = new MediaItem(itemObject);
      this.searchResults.media_results.push(newItem);
      this.numberOfSearchResults++;
      return undefined;
    });
  }

  processNativeResults(nativeResultsArray: any[]) {
    nativeResultsArray.map((itemObject: any, index) => {
      let newItem = new NativeItem(itemObject);
      this.searchResults.native_results.push(newItem);
      this.numberOfSearchResults++;
      console.log(itemObject);
      return undefined;
    });
  }

  @action setSearchResults(data: SearchResultSetI) {
    //Need to parse data which is structured array of generic objects
    //external_results
    //media_results
    //native_results
    this.numberOfSearchResults = 0;
    this.searchCompleted = true;
    this.searchResults = this.getEmptySearchResults();
    if (data.external_results) {
      this.processExternalResults(data.external_results);
    }
    if (data.media_results) {
      this.processMediaResults(data.media_results);
    }
    if (data.native_results) {
      this.processNativeResults(data.native_results);
    }
  }

  @action resetSearchResults() {
    this.searchCompleted = false;
    this.searchResults = this.getEmptySearchResults();
  }

  @action setError(isInError: boolean, message: string): void {
    this.isInError = isInError;
    this.errorMessage = isInError ? message : '';
  }

  cloudServerAddress(): string {
    if (MADataModel.inDevNode) {
      return 'https://tutuku.whakapapapuoro.co.nz';
    } else {
      return 'https://tutuku.whakapapapuoro.co.nz';
    }
  }

  APIURLString(): string {
    return `${this.cloudServerAddress()}/api/`;
  }

  ClientURLString(): string {
    return `${this.cloudServerAddress()}/`;
  }

  APIURLStringForAction(apiAction: string): string {
    return `${this.APIURLString()}${apiAction}`;
  }

  urlForItemWithID(itemID: number): string {
    return `${this.APIURLString()}client/item/${itemID}?ts=${
      Math.random() * 100000
    }`;
  }

  urlForMediaWithID(mediaItemId: number): string {
    return `${this.ClientURLString()}client/media/${mediaItemId}`;
  }

  urlForMediaThumbnailWithID(mediaItemId: number): string {
    return `${this.ClientURLString()}client/media/thumbnail/${mediaItemId}`;
  }

  urlForDNZTemplate() {
    return `${this.APIURLString()}client/dnz_template`;
  }

  public searchComplete(response: any) {}

  public doSearch(
    searchText: string,
    completionCallback: ALAJAXCallback
  ): void {
    this.dataIsLoading = true;
    let newRequest = new ALAjaxRequest(
      'search',
      { query: searchText },
      completionCallback
    );
    newRequest.send();
  }
}

type MASearchCallback = (status: ALAJAXStatus, payload: {}) => void;

export { MADataModel, ALAJAXStatus, ALAjaxRequest };
