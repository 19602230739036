import styled from "styled-components";
import * as React from "react";
import { TextParagraph } from "./TextStyles";

interface IFooterProps {
	fontSize?: number;
}

const Container = styled("div")`
	position: fixed;
	bottom: 10px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

class MAFooter extends React.Component<IFooterProps, {}> {
	render() {
		return (
			<Container>
				<TextParagraph fontSize={"13px"} color={"white"}>
					Copyright (2021) L Kewene-Doig tutukuinfo@gmail.com. Coding by AppLab
					Ltd.
				</TextParagraph>
			</Container>
		);
	}
}

export { MAFooter };
