import * as React from 'react';
import styled from 'styled-components';

import {TextParagraph} from '../TextStyles';
import { CoreItem } from './CoreItem';
import {MAImage} from '../MAImage';
import { MABadge } from '../MABadge';

interface IItemListRowProps {
    item: CoreItem;
    onClick:(item:CoreItem)=>void;
}

export const ItemListSectionHeader=styled.div`
    width: 100%;
    height: 28px;
    padding: 0 4px 0 4px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: rgb(241,123,0);
    display: flex;
    text-align: center;
    align-content:center;
    align-items:center;
    border-bottom: 1px solid silver;
`;


const RowContainer=styled.button`
    border:0;
    padding: 4px 14px 4px 4px;
    width:100%;
    min-height: 50px;
    background: rgba(255,255,255,1);
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: center;
    &:not(last) {
        border-bottom: 1px solid #f0f0f0;
    }
    &:hover {
        background-color: rgba(253,238,213,1);
    }
    &:active {
        background-color: rgba(221,202,172,1);
    }
`;

const InfoContainer=styled.div`
    flex: 10;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: flex-start;
    justify-content: center;
`;




export class MAItemListRow extends React.Component<IItemListRowProps,{}> {

    rowWasClicked() {
        if (this.props.onClick) {
            this.props.onClick(this.props.item);
        }
    }

    public render() {
        return (
            <RowContainer onClick={()=>this.rowWasClicked()}>
                <MAImage 
                    src={this.props.item.getThumbnailURL()} 
                    width={'60px'}
                    height={'60px'}
                />
                <InfoContainer>
                    <TextParagraph color='#4d4d4d' fontSize={'18px'} alignment={'left'}>
                        {this.props.item.getTitle() || 'No title'}
                    </TextParagraph>
                    <TextParagraph color='#909090' fontSize={'14px'} alignment={'left'}>
                        {this.props.item.shortDescription() || 'No description'}
                    </TextParagraph>
                </InfoContainer>
                <MABadge color={'#a0a0a0'}>
                    {this.props.item.getCategory()}
                </MABadge>
           </RowContainer>
        );
    }
}

