import * as React from "react";
import * as TextStyles from "../_lib/TextStyles";
import styled from "styled-components";
import { observer } from "mobx-react";
import { MANavigationSet } from "../_lib/MANavigationItem";

import { MADataModel } from "../_lib/MADataModel";
import { FaAngleLeft, FaExpandArrowsAlt } from "react-icons/fa";

export const kHeaderHeight: number = 155;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: centr;
  align-items: center;
  padding: 0;
  border-bottom: 1px solid #2d2d2d;
  background-color: #101010;
  color: white;
  z-index: 1000;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  color: white;
  height: 120px;
  min-height: 120px;
  overflow: hidden;
  background-color: #101010;
  width: 100%;
`;

interface IMANavigationRowProps {
  title: string;
  backAppSectionKey?: string;
  popCallback?: () => void;
}

const NagigationRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;
  min-height: 35px;
  justify-content: space-between;
  justify-items: space-between;
  align-items: center;
  padding: 4px;
  background-color: #722f1a;
  border-bottom: 1px solid #593102;
`;

const NavigationBackLink = styled.button`
  display: flex;
  color: #c0c0c0;
  font-family: sans-serif;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 4px;
  padding: 2px 6px 2px 0;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #e0e0e0;
    font-weight: 600;
  }
  &:active {
    font-weight: 600;
    color: white;
  }
`;

const NavigationFullScreenLink = styled.button`
  color: #c0c0c0;
  font-family: sans-serif;
  font-size: 16px;
  background-color: transparent;
  text-align: right;
  float: right;
  border: 0;
  &:hover {
    color: #c0c0c0;
    background-color: rgba(200, 200, 200, 0.2);
  }
  &:active {
    background-color: rgba(200, 200, 200, 0.1);
  }
`;

interface IMAPageHeaderProps {}

const NavigationRowFlexItem = styled.div`
  flex: 3;
`;

const NavigationRowTitleItem = styled.div`
  text-align: center;
  flex: 6;
`;

@observer
export class MAPageHeader extends React.Component<IMAPageHeaderProps, {}> {
  constructor(props: IMAPageHeaderProps) {
    super(props);
    this.state = {};
  }

  renderBackLink(navSet: MANavigationSet) {
    const completionCallback = navSet.previousItem
      ? navSet.previousItem.popCompletionCallback
      : undefined;
    return (
      <NavigationRowFlexItem>
        <NavigationBackLink
          onClick={() =>
            MADataModel.sharedInstance().popAppNavigationStack(
              completionCallback
            )
          }
        >
          <FaAngleLeft size={"19px"} />
          {navSet.previousItem ? navSet.previousItem.getBackTitle() : "Back"}
        </NavigationBackLink>
      </NavigationRowFlexItem>
    );
  }

  renderFullScreen() {
    // return (<div/>);
    return (
      <NavigationRowFlexItem>
        <NavigationFullScreenLink
          onClick={() => MADataModel.sharedInstance().toggleFullScreen()}
        >
          <FaExpandArrowsAlt size={"19px"} />
        </NavigationFullScreenLink>
      </NavigationRowFlexItem>
    );
  }

  render() {
    const currentNavSet: MANavigationSet = MADataModel.sharedInstance()
      .currentAppNavigationSet;
    return (
      <HeaderContainer>
        <TitleContainer>
          <TextStyles.MAPageTitle>Tutuku</TextStyles.MAPageTitle>
          <TextStyles.MAPageSubtitle>
            The passing on of knowledge
          </TextStyles.MAPageSubtitle>
        </TitleContainer>
        <NagigationRow>
          <NavigationRowFlexItem>
            {currentNavSet.canGoBack()
              ? this.renderBackLink(currentNavSet)
              : ""}
          </NavigationRowFlexItem>
          <NavigationRowTitleItem>
            {currentNavSet.currentItem.getTitle()}
          </NavigationRowTitleItem>
          <NavigationRowFlexItem>
            {this.renderFullScreen()}
          </NavigationRowFlexItem>
        </NagigationRow>
      </HeaderContainer>
    );
  }
}
