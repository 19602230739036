import * as React from 'react';
import styled from 'styled-components';

import { ContainerBox } from '../_lib/ContainerBox';
import { MADataModel,SearchResultSetI} from '../_lib/MADataModel';
import { ALAjaxRequest,ALAJAXResponse } from '../_lib/ALAjaxRequest';
// import { MAErrorView } from '../_lib/MAErrorView';
import { CoreItem } from '../_lib/Item/CoreItem';
import { MAItemListRow, ItemListSectionHeader } from '../_lib/Item/MAItemRow';
import { MANavigationItem } from '../_lib/MANavigationItem';
import {MAContentContainer} from './MAContentContainer';
import {MALoadingView} from "../_lib/MALoadingView";
import * as TextStyles from "../_lib/TextStyles";

interface ISectionExpansionInterface {
    showNativeItems:boolean;
    showExternalItems:boolean;
    showMediaItems:boolean;
}


interface IHResultsPageProps {
    history?: any,
}

interface IHResultsPageState {
    searchComplete: boolean;
    isLoading: boolean;
    searchWasCancelled: boolean;
    displayedTypes:ISectionExpansionInterface;
    showError: boolean;
    errorMessage: string;
}


const ResultsListScrollView=styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 6px;
    border: 1px solid #4d4d4d;
    background-color: white;
    padding: 0;
`;



export class MAResultsPage extends React.Component<IHResultsPageProps,IHResultsPageState> {

    constructor(props:IHResultsPageProps) {
        super(props);
        this.state = {
            searchComplete: MADataModel.sharedInstance().searchCompleted,
            isLoading: false,
            searchWasCancelled: false,
            showError: false,
            errorMessage: '',
            displayedTypes:{
                showNativeItems:true,
                showExternalItems:true,
                showMediaItems:true,
            }
        };
    }

  
    dismissError() {
        this.setState({
            showError: false,
            errorMessage: ''
        });
    }


    itemRowWasClicked(item:CoreItem) {
       if (item.itemClass==='external') {
           //Show external item here
           MADataModel.sharedInstance().setSelectedItem(item);
           const newNavItem=new MANavigationItem(
            item.getTitle(),
               'Item',
               'externalitem'
            );
           MADataModel.sharedInstance().pushAppNavigationStack(newNavItem);
       }
       else {
        MADataModel.sharedInstance().setSelectedItem(item);
        const newNavItem=new MANavigationItem(
            item.getTitle(),
               'Item',
               'nativeitem'
            );
        MADataModel.sharedInstance().pushAppNavigationStack(newNavItem);
       }
    }


    searchComplete(responseObject: ALAJAXResponse) {
        if (! this.state.searchWasCancelled) {
            if (responseObject.status.code!==0) {
                MADataModel.sharedInstance().setError(true,'Error parsing response json');
            }
            else {
                MADataModel.sharedInstance().setSearchResults(responseObject.payload as SearchResultSetI);
                this.setState({
                    isLoading: false,
                    searchComplete: true,
                });
            }
        }
    }


    loadFailed(wasCancelled:boolean) {
        this.setState({
            isLoading:false,
            searchWasCancelled: true,
        });

        //Need to cancel the search now...
       
    }


    doSearch() {
        this.setState({
            isLoading:true,
            searchWasCancelled: false,
        });
        const sentData={
            query: MADataModel.sharedInstance().searchValue
        };
        let newRequest=new ALAjaxRequest(
            'search',
            sentData,
            (responseObject: ALAJAXResponse)=> this.searchComplete(responseObject)
        );
        newRequest.send();
    }


    showLoading() {
        return (
            <MALoadingView
                message={'Please wait, loading search results'}
                callback={(wasCancelled:boolean)=>this.loadFailed(wasCancelled)}
            />
        );
    }

    componentDidMount(): void {
        if (! this.state.searchComplete) {
            this.doSearch();
        }
    }


    //
    // searchComplete(responseObject: ALAJAXResponse) {
    //     // MADataModeodel.sharedInstance().hideLoadingView();
    //     MADataModel.sharedInstance().setSearchResults(responseObject.payload as SearchResultSetI);
    //     if (responseObject.status.code!==0) {
    //        MADataModel.sharedInstance().setError(true,'Error parsing response json');
    //     }
    //     else {
    //         this.setState({
    //             resultsReady: true,
    //         });
    //     }
    // }



    renderNativeResults() {
        return (
            <ContainerBox direction={'column'} justifyContent={'space-between'} >
                 <ItemListSectionHeader>
                    Native results ({MADataModel.sharedInstance().searchResults.native_results.length} results)
                </ItemListSectionHeader>
                {
                MADataModel.sharedInstance().searchResults.native_results.map((item:CoreItem,index:number) =>{
                    return (
                        <MAItemListRow 
                            key={`native-item-${index}`}
                            item={item} 
                            onClick={()=>this.itemRowWasClicked(item)}
                         />
                    );
                })
                }
            </ContainerBox>
        );
    }

    renderExternalResults() {
        return (
            <ContainerBox direction={'column'} justifyContent={'space-between'} >
                <ItemListSectionHeader>
                    External results ({MADataModel.sharedInstance().searchResults.external_results.length} results)
                </ItemListSectionHeader>
                {
                MADataModel.sharedInstance().searchResults.external_results.map((item:CoreItem,index:number) =>{
                    return (
                        <MAItemListRow 
                            key={`native-item-${index}`}
                            item={item} 
                            onClick={()=>this.itemRowWasClicked(item)}
                         />
                    );
                })
                }
            </ContainerBox>
        );
    }

    renderMediaResults() {

    }


    renderResults() {
        const numberOfResults=MADataModel.sharedInstance().numberOfSearchResults;
        if (numberOfResults>0) {
            return (
                <MAContentContainer>
                    <ResultsListScrollView>
                <ContainerBox direction={'column'} justifyContent={'space-between'} >
                    {
                        this.renderNativeResults()
                    }
                    {
                        this.renderExternalResults()
                    }
                    {
                        this.renderMediaResults()
                    }
                </ContainerBox>
                    </ResultsListScrollView>
                </MAContentContainer>
            );
        }
        else {
            return (
                <ContainerBox direction={"column"}>
                    <br />
                    <br />
                    <br />
                    <TextStyles.TextParagraph
                        color={'white'}
                        fontSize={'48px'}
                    >
                    Whoops
                    </TextStyles.TextParagraph>
                    <TextStyles.TextParagraph
                        color={'white'}
                        fontSize={'24px'}
                    >
                        no results found.
                    </TextStyles.TextParagraph>
                    <br />
                    <br />
                    <TextStyles.TextParagraph
                        color={'white'}
                        fontSize={'18px'}
                    >
                        Try a different search term or check for typos!
                    </TextStyles.TextParagraph>
                </ContainerBox>
            );
        }

    }


    render() {
        return  this.state.isLoading ? this.showLoading() : this.renderResults()

    }
}

