import {CoreItem} from './CoreItem';
import moment, { Moment } from 'moment';
import AppGlobal from '../AppGlobal';
import { MediaItem } from './MediaItem';


export class NativeItem extends CoreItem {

    comment_id: Number;
    endDate: Moment;
    geoLocation:Location;
    imageURL: String;
    itemID: Number;
    media: MediaItem[];
    mediaID: Number;
    personID: Number;
    publicationDate: Moment;
    radius: Number;
    sourceURL: string;
    startDate: Moment;
    teReoDescription: String;
    teReoTitle: String;

 
    extractMedia(rawMediaArray: []) {
        return rawMediaArray.map((rawObject: any, index: number) => {
            return new MediaItem(rawObject);
        });

    }

    constructor(fields:any={}) {
     super('native',fields);
     //Now extract any native_iem specific field values
     this.comment_id=fields.comment_id || AppGlobal.kNullNUmberSentinel;
     this.endDate= moment(fields.endDate || '', AppGlobal.kDefaultDateFormat);
     this.geoLocation=fields.geo_location || null;
     this.imageURL=fields.imageURL || null;
     this.itemID=fields.item_id || AppGlobal.kNullNUmberSentinel;
     this.mediaID=fields.media_id || AppGlobal.kNullNUmberSentinel;
     this.personID=fields.person_id || AppGlobal.kNullNUmberSentinel;
     this.publicationDate=fields.published_date || null;
     this.radius=fields.radius || AppGlobal.kNullNUmberSentinel;
     this.sourceURL=fields.source_url || null;
     this.startDate=fields.start_date || null;
     this.teReoDescription=fields.te_reo_description || null;
    this.teReoTitle = fields.te_reo_title || null;
    this.media = this.extractMedia(fields.media);
    console.log(this.media);
    }
    
    getThumbnailURL():string {
        return '/img/media/native_type.png';
    }

  
}


